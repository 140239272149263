/* Import slick carousel CSS */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@font-face {
  font-family: "Bernardo Moda Bold";
  src: url("../fonts/bernardo-moda/Bernardo\ Moda.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
body, html {
  margin: 0;
  padding: 0;
}

.bouncing-ball {
  width: 20px;
  height: 20px;
  background-color: #e75600;
  border-radius: 50%;
  animation: bounce 2s infinite;
}
.bouncing-ball:nth-child(2) {
  animation-delay: 0.33s;
}

.bouncing-ball:nth-child(3) {
  animation-delay: 0.66s;
}
@keyframes buttonbounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(30px);
  }
}
.logo {
  height: 60px;
  width: auto;
  max-width: 80px;
  margin: 4px; 
  max-height: 70px;
}
.userimage{
 margin-top: 2px;
    margin-left: 2px;
    vertical-align:middle;
    height: 15px;
    width: 15px;
    transition-property: background-color, font-size, transform, color;
    transition-timing-function: ease-in-out;
    transition-duration: 2s;
    
    animation: fadeIn 2s;}
.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transition: background-color 0.3s;
    z-index: 1000;
    border-bottom: none;
    box-shadow: none;
    height: 90px;
    font-weight: lighter;
}

.header.scrolled {
  background-color: white;
  
  font-weight: lighter;
}
.header h1 {
    text-align: left;
    color: white; /* Change to black when scrolled */
    font-size: 12px; /* Adjust font size as needed */
    margin-left: 10px;
  }
  
  .header.scrolled h1 {
    color: black; /* Change color to black on scroll */
  }
.banner {
 /* height: auto; 
  background-size: 100% auto; 
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
 */ width: 100%;
    margin: 0 auto;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
}
.section4banner {
   height: auto; /* Set height to auto to adapt based on content */
  background-size: 100% auto; /* Make the image fit the width of the screen */
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  width: 100%; /* Ensure full width */
  }
.black-section {
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.black-section-text {
  color: white;
  text-align: center;
  font-size: smaller;
  margin-left: 3px;
  margin-right: 3px;
}
.section4wrap{
    display: flex;
    flex-direction: column;
}
.section4 {
  height: 50vh;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section4-text {
  color: black;
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
}

.card-container {
  display: flex;
  overflow-x: auto;
  padding: 20px;
  white-space: nowrap; /* Ensure the cards stay in one line */
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-top: 15px;
}
.black-section-para{
  width: 100%; /* Default width for smaller screens */
  margin: 0 auto; 
}
.swipertext-overlay {
    position: absolute;
  bottom: 20px; /* Adjust based on where you want the text */
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7); /* Optional: add text shadow for better visibility */
  text-align: center;
  }
  
  .swipertext-overlay h2 {
    margin: 0;
  }
.productcard {
  background: white;
 /* box-shadow: 0 4px 8px rgba(0,0,0,0.1);*/
  width: 220px;
  height: 310px; /* Fixed height for the card */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
}
.swipercard{
    background: transparent;
    
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 270px;
    height: 480px; /* Fixed height for the card */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.swipercard img{
    width: 100%;
    height: 100%;
    object-fit:cover; 
}
.card img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire card area */
  
}
.cardimage{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire card area */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.inputs{
    vertical-align:middle;
    display: flex;
  justify-content: center;
  margin-top: 10px;
  flex-direction: row;
  }
  .iconstyle{
  margin: 0 10px;
  text-decoration: none;
}
.card-text {
  margin-top: 10px;
  text-align: center;
}
.goldiesimg{
    width: 160px;
    height: 85px;
}
.regheader{
    color: white;
    font-size: 30px;
    margin-top: -5px;
    text-align: center;
}
.regtext{
    color: white;
    
    margin-top: -15px;
    font-size: 20px;
    text-align: center;
}
.registration{
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.popupregistration{
  height: 90vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.section6 {
 /* height: 55vh;
  background-size: cover;
  background-position: center;
  position: relative;*/
  height: auto; /* Set height to auto to adapt based on content */
  background-size: 100% auto; /* Make the image fit the width of the screen */
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
}
.section6text{
  margin-left: -20%;
  position: 'absolute';
      top: '70%';
      left: '55%'; 
      transform: 'translate(-50%, -50%)', 
}
.text-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
}

.text-overlay h2 {
  margin: 0;
}

.text-overlay p {
  margin: 0;
}

.swiper-container {
  width: 100%;
  height: 400px;
  margin-top: 10px;
}

.slide-content {
  text-align: center;
  margin: 10px;
}

.slide-content img {
  width: 100%;
  height: 70%;
  object-fit: cover;
}

.slide-content p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}


/* Floating Overlay Button */
.floating-overlay-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 18px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  animation: buttonbounce 1.5s infinite;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it stays above other elements */
}
.floating-overlay-closebutton {
  position: fixed;
 /* top: 10px;
  right: 10px;*/
  margin-top: 135%;
  padding: 8px 14px;
  background-color: gray;
  color: white;
  border: none;
  font-weight: bold;
  border-radius: 20px;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it stays above other elements */
}
.floating-overlay-button:hover {
  background-color: grey;
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* Ensure it's above everything */
}

/* Popup Box */
.popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}
.overlay-icons {
  position: absolute;
  bottom: 200px;  /* Position icons at the bottom of the banner */
  right: 18px;   /* Align icons to the right */
  display: flex;
  flex-direction: column; /* Stack icons vertically */
 /* gap: 1px;   Space between icons */
}

.store-icon {
  width: 130px; /* Icon size */
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.store-icon:hover {
  transform: scale(1.1); /* Slight zoom effect */
}


/* For screens larger than 768px (laptop screens) */
@media (min-width: 768px) {
  .store-icon {
    width: 150px; /* Icon size */
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  .header {
   /* position: fixed; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    flex-direction: column;
    background-color: black;
    z-index: 1000;
    transition: background-color 0.3s;*/
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transition: background-color 0.3s;
    z-index: 1000;
    border-bottom: none;
    box-shadow: none;
    height: auto; /* Adjust height based on content */
    font-weight: lighter;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start;
  }
  .black-section-text {
    color: white;
    text-align: center;
    font-size: smaller;
    margin-left: 3px;
    margin-right: 3px;
    width: 60%;
  }
  .black-section-para{
    width: 60%;
  }
  .card-container {
    display: flex;
    overflow-x: auto;
    padding: 20px;
    white-space: nowrap; /* Ensure the cards stay in one line */
  }
  .card-container.center {
    justify-content: center;
  }
  .logo {
    height: 60px;
    width: auto;
    max-width: 80px;
    margin: 4px; 
    max-height: 70px;
  }
  .section4wrap{
    flex-direction: row;
    height: 70vh;
}
  .banner {
    width: 100%;
    margin: 0 auto;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
   /* height: 100%; 
  background-size: 100% auto;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  object-fit: cover;*/
  }
  
  .section4banner {
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-position: left;
    
  }
  .text-overlay {
    position: absolute;
    bottom: 20px;
    left: 20%;
    color: white;
  }
  .section4 {
    height: 70vh;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
  }
  .header h1 {
    text-align: left;
    color: white;
    font-size: 12px;
    margin-left: 10px;
  }
  .section6 {
   /* width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    max-height:fit-content;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;*/
    height: auto; /* Set height to auto to adapt based on content */
  background-size: 100% auto; /* Make the image fit the width of the screen */
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  }
  
  .header.scrolled h1 {
    color: black; /* Change color to black on scroll */
  }
  .goldiesimg{
    width: 220px;
    height: 120px;
}

/* Floating Overlay Button */
.floating-overlay-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 14px 20px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  animation: buttonbounce 1.5s infinite;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it stays above other elements */
}
.floating-overlay-closebutton {
  position: fixed;
  /*top:10px;
  right: 20px;*/
  margin-top: 39%;
  padding: 8px 14px;
  background-color: gray;
  color: white;
  border: none;
  
  font-weight: bold;
  border-radius: 20px;
  font-size: 13px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it stays above other elements */
}
.floating-overlay-button:hover {
  background-color: grey;
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* Ensure it's above everything */
}

/* Popup Box */
.popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  max-height: 90%;
  width: 100%;
  height: 90%;
}
}
