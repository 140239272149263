body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: black;
    color: white;
  }
  
  .App {
    text-align: center;
  }
  
  /* Header Section */
  .header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transition: background-color 0.3s;
    z-index: 1000;
    border-bottom: none;
    box-shadow: none;
    height: 90px;
    font-weight: lighter;
}

.header.scrolled {
  background-color: white;
  
  font-weight: lighter;
}
.header h1 {
    text-align: left;
    color: white; /* Change to black when scrolled */
    font-size: 12px; /* Adjust font size as needed */
    margin-top: -35px;
    margin-left: 10px;
  }
  
  .header.scrolled h1 {
    color: black; /* Change color to black on scroll */
  }
  
  .logo {
    height: 90px;  
    margin-bottom: 20px;
    width: auto;  
    justify-content: left;
  }
  
  .language-selector {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  /* Promo Section */
  .promo-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  
  .promo-text {
    max-width: 50%;
    text-align: left;
    margin-left: 10%;
  }
  
  .subtitle {
    color: #fff;
    font-size: 20px;
  }
  
  .title {
    font-size: 36px;
    font-weight: bold;
  }
  
  .title span {
    color: #825dfd;
  }
  
  .features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
  }
  
  .deal-section {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
  }
  
  .claim-deal {
    background-color: #d3fd4a;
    color: black;
    font-size: 18px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .timer {
    background-color: #333;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
  }
  
  .guarantee {
    font-size: 16px;
    margin-top: 20px;
  }
  
  .promo-image img {
    max-width: 50%;
    height: auto;
    margin-top: 15%;
    margin-left: 20%;
  }
  .promo-image video{
    margin-left:50px;
    height:'40%';
    margin-top:70px;
  }
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .promo-section {
      flex-direction: column;
      
      text-align: center;
    }
  
    .promo-text {
      max-width: 100%;
      text-align: center;
      margin-top: 25%;
    }
  
    .promo-image img {
      max-width: 80%;
      
    margin-left: 0%;
    }
    .promo-image video{
        max-width: 80%;
      
        margin-left: 0%;
      }
  }
  